import { Button, ButtonThemes, ButtonTypes } from '@design-system/ui-kit-alpha';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AuthContext from '../../context/AuthContext';
import styles from './Header.module.scss';

const Header = (): JSX.Element => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);

  return (
    <div className={styles.header}>
      <img src='https://cdn.id.mitgo.com/mitgo_id/images/logoMitgoID.svg' alt='logo' />
      <Button type={ButtonTypes.reset} onClick={() => logout()} theme={ButtonThemes.ghostFill}>
        {t('action.logout')}
      </Button>
    </div>
  );
};

export default Header;
